.CardTotalDetail {
    padding: 10px;
}

.CardTotalDetail p {
    padding: 0px;
    margin: 0px;
}

.itemsCard {
    display: flex;
    flex-direction: column;
}

.Title {
    margin: 10px;
}

.itemCard,
.totalInfo {
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.priceText {
    /* min-width: 20%; */
}


.lineAbove {
    padding: 0px;
    margin: 0px;
    width: 100%;
    border-bottom: 1px solid var(--black-75, #595959) !important
}