:root {
  --bg-color: #039ecc;
  --bg-secondary-color: #c0e6f2;
  --bg-color-premium: #e6b331;
  --bg-secondry-color-premium: #fad882;
  --border-radius: 10px;
  font-family: 'Montserrat' !important;
}

.titulo {
  color: var(--Black, #212121);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
}

.beneficiosTexto {
  color: var(--Black, #212121);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-right: 15px;
  align-self: flex-start;
  width: 100%;
  margin-top: 40px;
}

.innerItem {
  display: flex;
  align-items: center;
}

.item {
  border-bottom: 1px solid var(--black-50, #8f8f8f);
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 10px;
}

.text {
  color: var(--Black, #212121);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.cardReferidos {
  border-radius: 8px;
  border: 1px solid var(--Azul-Inter, #039ecc);
  background: var(--Azul-Inter, #039ecc);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardText {
  color: var(--White, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.032px;
  width: 198.66px;
  text-align: center;
  margin-left: 8px;
}

.hashtag {
  color: var(--White, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.032px;
  text-align: center;
  margin-left: 8px;
}

.buttonReferidos {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background-color: var(--White, #fff);
  padding: 14px 16px;
  color: var(--Azul-Inter, #039ecc);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border: 0px;
}

.cardContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cardBackground {
  position: relative;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  /* width: 328px; */
  min-width: 268px;
  overflow: hidden;
  align-self: center;
}

.topCardImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.cardLine {
  position: absolute;
  top: 40px;
  left: 10px;
  width: 95%;
}

.cardLeftWave {
  position: absolute;
  left: 0px;
  width: 80%;
}

.cardLinesBackground {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.5;
  width: 100%;
}

.certificadoContainer {
  display: flex;
  position: absolute;
  top: 50px;
  left: 20px;
  width: 100%;
}

.titleCardText {
  color: var(--White, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
}

.subtitleCardText {
  color: var(--White, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-left: 5px;
}

.titularContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 80px;
  left: 20px;
}

.planContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 110px;
  left: 20px;
}

.vigenciaContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 140px;
  left: 20px;
}

.bottomCard {
  background-color: var(--bg-color-premium);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  margin-top: 0px;
  width: 99%;
  align-self: center;
}

.bottomCardText {
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.referidosContainer {
  display: flex;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
}

.innerReferidos {
  width: 500px;
}

.coberturasContainer {
  display: flex;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
}

.innerCoberturas {
  width: 500px;
}

/* Tablet */
@media screen and (min-width: 640px) and (max-width: 1023px) {
  .titulo {
    text-align: left;
  }

  .tituloContainer {
    width: 500px;
    align-self: center;
  }

  .tituloMain {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cardBackground {
    width: 461px;
    overflow: hidden;
  }

  .bottomCard {
    width: 461px;
  }
}

/*Laptop*/
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .titulo {
    text-align: left;
  }

  .tituloContainer {
    width: 500px;
    align-self: center;
  }

  .tituloMain {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cardBackground {
    width: 461px;
    overflow: hidden;
  }

  .bottomCard {
    width: 461px;
  }

}

/* Desktop */
@media screen and (min-width: 1200px) and (max-width: 2096px) {
  .titulo {
    text-align: left;
  }

  .tituloContainer {
    width: 500px;
    align-self: center;
  }

  .tituloMain {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .mainContainer {
    display: flex;
    align-self: center;
  }

  .referidosContainer {
    margin-top: 40px;
  }

  .cardBackground {
    width: 461px;
    overflow: hidden;
  }

  .bottomCard {
    width: 461px;
  }

  .cardBackgroundImage {
    width: 100%;
  }

  .cardContainer {
    height: 247px;
  }

  .swiper-initialized {
    max-width: 560px;
    height: 115%;
  }

}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 10px !important;
  border-radius: 10px !important;
  border: #494949 !important;
  background-color: #494949 !important;
  /* display: none!important; */
}

.swiper-pagination-bullet-active {
  width: 30px !important;
  height: 10px !important;
  border-radius: 10px !important;
  background: var(--Azul-Inter, #039ECC) !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 24px !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--Azul-Inter, #039ECC) !important;
  top: 43% !important;
}

@media screen and (min-width: 0px) and (max-width: 400px) {


  .swiper-wrapper {
    gap: 12px !important;
  }
}

@media screen and (min-width: 401px) and (max-width: 600px) {


  .swiper-wrapper {
    gap: 16px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 840px) {


  .swiper-wrapper {
    gap: 1rem !important;
  }
}