.containerCard {
    border: 1px solid var(--bg-color);
    border-radius: 10px;
    margin: 10px;
}

.MembershipCardDetail {
    display: flex;
    flex-direction: column;
}

.labelBenefit {
    text-align: center;
    background-color: #C0E6F2;
    font-size: 20px;
}

.labelBenefit p {
    padding: 10px;
}

.PremiumText p {
    text-align: center;
    padding: 20px;
    margin: 15px;
    font-size: 22px;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;

}

.buttonGroup .itemGroup {
    padding: 7px;
    width: 100px;
    font-size: 15px;
    font-weight: bold;
    background-color: transparent;
    color: black;
    border: 2px solid #FFD60E;
}

.itemGroupActive {
    background: #E6B331 !important;
    border: 2px solid #E6B331 !important;
    color: white !important;
}



.itemGroupActive:after,
.itemGroupActive:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.itemGroupActive:after {
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
}

.itemGroupActive:before {
    border-top-color: #E6B331;
    border-width: 10px;
    left: 50%;
    margin-left: -10px;
    margin-top: 0;
}

.buttonGroup .itemGroup:hover {
    border: 2px solid #FFD60E !important;

}