@keyframes scale {

	to {
		width: 50px;
		background-color: rgba(66, 182, 217, 1);
	}
}

* {
	font-family: 'Montserrat';
}

h1.section-title {
	font-weight: 700;
	font-size: clamp(20px, 24px, 28px);
	text-align: center;
	line-height: 24px;
	font-style: normal;
	margin-bottom: 1rem;
}

h5.section-subtitle {
	color: var(--black, #212121);
	text-align: center;
	font-family: Montserratt;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

div.gallery-slider-container {
	overflow-x: scroll;
	cursor: grab;
	scroll-behavior: smooth;

	width: clamp(120px, 85vw, 390px);
}

div.gallery-slider-container::-webkit-scrollbar {
	display: none;
}

.gallery-slider-container>.gallery-slider {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-shrink: 0;
	width: fit-content;
	padding: 10px 10px;
}

.react-swipeable-view-container>div {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.gallery-slider>.card {
	height: 370px;
	width: clamp(120px, 85vw, 360px);
	border-radius: 8px;
	border: 1px solid var(--azul-inter, #039ECC);
	background: var(--azul-5, #F2FAFC);
	display: flex;
	padding: 16px 5px;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	align-self: stretch;
}

.gallery-slider-active>.card {
	height: 370px;
	width: clamp(338px, 32vw, 330px);
	border-radius: 8px;
	border: 1px solid var(--azul-inter, #039ECC);
	background: rgba(230, 179, 49, 0.05);
	display: flex;
	padding: 16px 5px;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	align-self: stretch;

}


.gallery-slider>.card:nth-child(even) {
	background-color: var(--azul-5, #F2FAFC);
}



.gallery-slider-container>.gallery-slider-active {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-shrink: 0;
	width: fit-content;
	padding: 10px 10px;
}


.gallery-slider-active>.card>.card-content>h3 {
	text-align: center;
}

.gallery-slider-active>.card>.card-content>p {
	text-align: center;
	font-size: clamp(12px, 1.5vw, 14px);
}

.gallery-slider-active>.card>.card-image {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.gallery-slider-active>.card>.card-image>img {
	width: clamp(160px, 25vw, 214px);
}



.gallery-slider>.card>.card-content>h3 {
	text-align: center;
}

.gallery-slider>.card>.card-content>p {
	text-align: center;
	font-size: clamp(12px, 1.5vw, 14px);
}

.gallery-slider>.card>.card-image {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.gallery-slider>.card>.card-image>img {
	width: clamp(160px, 25vw, 214px);
}

.gallery-slider>.card:nth-child(even) {
	background-color: var(--azul-5, #F2FAFC);
}



div.indicator-slider-container {
	display: flex;
	flex-direction: row;
	margin-top: 1.5rem;
}

div.indicator-slider-container>.indicator {
	width: 20px;
	height: 10px;
	background-color: #E6B331;
	border-radius: 10px;
	margin-right: 1rem;
}

div.indicator-slider-container>.indicator-active {
	width: 20px;
	height: 10px;
	background-color: rgba(192, 230, 242, 1);
	border-radius: 10px;
	margin-right: 1rem;
}

div.indicator-slider-container>.indicator:hover {
	cursor: pointer;
}

div.indicator-slider-container>.indicator.selected {
	animation: scale 300ms ease forwards;
}

.card>.card-image {
	pointer-events: none;
}

.card {
	display: flex;
	width: 100%;
	max-width: 240px;
	padding: 40px 16px 16px 16px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	border-radius: 8px;
	background: var(--azul-5, #F2FAFC);
	min-width: 200px;
	;
}

.card-active {
	min-width: 100px;
	max-width: 240px;
	display: flex;
	width: 100%;
	padding: 40px 16px 16px 16px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	border-radius: 8px;
	background: rgba(230, 179, 49, 0.05);
}

.card-content {
	display: flex;
	align-items: center;
	gap: 23px;
}

.content-card-background {
	display: flex;
	width: 100%;
	min-width: 260px;
	max-width: 262px;
	padding: 16px 20px;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	border-radius: 8px;
	border: 1px solid var(--azul-inter, #039ECC);
	border-radius: 8px;
	background: var(--white, #FFF);

	margin: 'auto 10px';
}

.content-card-background-active {
	display: flex;
	width: 100%;
	padding: 16px 20px;
	min-width: 260px;
	max-width: 262px;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	border-radius: 8px;
	border: 1px solid #E6B331;
	border-radius: 8px;
	background: var(--white, #FFF);


}

.icons-content {
	position: relative;
	margin-left: -70px;

}

.container-gallery-buttons {
	display: flex;
	width: 100%;
	min-width: 308px;
	max-width: 600px;
	align-items: flex-start;
	border-radius: 8px;
}

@media screen and (min-width:0px) and (max-width:320px) {
	.react-swipeable-view-container {
		max-width: 310px;
	}

	.content-card-background {
		margin: auto 10px !important;
	}
}

@media screen and (min-width:321px) and (max-width:600px) {
	.react-swipeable-view-container {
		max-width: 350px;
	}
}

@media screen and (min-width:0px) and (max-width:320px) {

	.content-card-background-active {
		margin: auto 10px !important;
	}
}
