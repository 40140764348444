:root {
    --bg-color-premium: #E6B331;
}

.cardPremium {
    border: 1px solid var(--bg-color-premium);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-width: 850px;
    margin: 10px;
    margin-bottom: 15px;
    background-color: rgba(230, 179, 49, 0.10);
}

.imagePremium {
    background-color: var(--bg-color-premium);
    display: flex;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
}

.imageFilePremium {
    max-width: 200px;
}

.textPremium {
    text-align: center;
    padding: 15px;
    font-size: 22px;
}

.buttonPremium {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    padding: 15px;
}

.buttonPremium button {
    background-color: var(--bg-color-premium);
    border: none;
    border-radius: 8px;
    width: 100%;
    padding: 20px;
    font-weight: 500;
    color: white;
}

.butttonItem {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}