.conteinerReferred {
    max-width: 70%;
    margin: auto;
}

.title {
    padding: 15px;
    max-width: 60%;
    margin: auto;
}

.notice {
    padding: 15px;
    font-size: 20px;
    text-align: center;
    width: '50%';
}

.buttonArea {
    padding: 10px;
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.buttonArea Button {
    font-weight: 500;
    font-size: 16px;
}

.primary {
    background-color: var(--bg-color) !important;
    color: white !important;
}

.primary:hover {
    background-color: var(--bg-color) !important;
    color: white !important;
}

.imageBanner {
    display: flex;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 300px;
    max-width: 400px;
    margin: auto;
    padding-top: 100px;
}

.guy {
    width: 100%;
    max-width: 100px;
    right: 220px;
    bottom: 70px;
    position: absolute;
    z-index: 1000;
}

.stadistics {
    position: relative;
}

@media screen and (min-width:0px) and (max-width:639px) {
    .conteinerReferred {
        max-width: 100%;
        margin: auto;
    }

    .title {
        padding: 15px;
        max-width: 100%;
        margin: auto;
    }
}