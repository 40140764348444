.itemCardBenefit {
    padding: 15px;
    margin: auto;
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

.icon {
    padding: 5px;
}

.title {
    text-align: center;
    margin: 5px;
    padding-top: 10px;
    font-weight: 700;
    font-size: 18px;
}

.titleItem p,
.textDescription p {
    padding: 0px !important;
    margin: 0px !important;
}

.titleItem {
    font-size: 18px;    
    font-weight: 700;
    text-align: left !important;
}

.textDescription {
    font-size: 16px;
    font-weight: 400;
    text-align: left !important;
}