.error-message, 
.error-message > div > input,
.error-message > div > select,
.error-message > p, 
.error-message > label {
    color: red;
}

div > span > textarea:focus-visible {
    outline: none;
}

.error-message > .MuiSelect-select, 
.error-message > div > input {
    border-bottom: 1px solid red; 
}
