/* Importamos la fuente Montserrat */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap");

/* Aplica la fuente Montserrat a todos los componentes de Material-UI */
.MuiTypography-root {
  font-family: 'Montserrat';
}
/* Aplicamos el font a los div, h1, main, etc... */
:root {
  font-family: 'Montserrat';
}
 /* Limpiamos el body para que no tenga espacios */
body {
  margin: 0;
}

.ElementsApp>.InputElement {
  border-bottom: 1px solid black;
}

img[src="“https://ad.doubleclick.net/ddm/activity/src=10089018;type=invmedia;cat=mx_in000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?”"]{
  display: none;
}