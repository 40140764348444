.PrincipalBox,
div,
p,
button {
    font-family: 'Montserrat' !important;
}

.MainConteiner {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.bannerConteiner {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    margin: 30px auto 30px auto;
    max-width: 800px;
}

.seguroTitle {
    display: flex;
    justify-content: center;
}

.LogoSeguro {
    padding: 10px;
}

.labelBanner {
    font-size: 22px;
    text-align: center;
}

.avatarWithHeart {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    position: relative;
}

.avatarWithPhone {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    position: relative;
}

.imageBannerCoverage {
    margin: auto;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.avatarImage {
    position: inherit;
    width: 100%;
}

.heartImage {
    width: 100%;
    max-width: 90px;
    left: 230px;
    top: 90px;
    position: absolute;
}

.phoneImage {
    width: 100%;
    max-width: 100px;
    left: 20px;
    top: 100px;
    position: absolute;
}

.buttonRegister {
    display: flex;
    justify-content: center;
}



.btnBlue {
    margin: 10px;
    width: 100%;
    max-width: 400px;
    border: none;
    padding: 15px;
    background-color: var(--bg-color);
    color: white;
    font-weight: 700;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.membershipCard {
    display: flex;
    position: relative;
    align-items: start;
    justify-content: center;
}

.buttonRegisterMobile {
    display: none;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 10px;
}

.buttonShow {
    display: flex !important;
}

.bannerText {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}



/*Mobile*/
@media screen and (min-width:0px) and (max-width:639px) {
    .MainConteiner {
        max-width: 100%;
    }

    .image {
        display: flex;
        position: relative;
    }

    .bannerText {
        margin: auto;
    }

    .heartImage {
        transform: scale(1);
        left: 220px;
        top: 60px;
        position: absolute;
    }

    .phoneImage {
        transform: scale(1);
        left: 25px;
        top: 100px;
        position: absolute;
    }
}

/*Tablet*/
@media screen and (min-width:640px) and (max-width:1023px) {
    .MainConteiner {
        max-width: 100%;
    }

    .image {
        display: flex;
        position: relative;
    }

    .labelBanner p {
        font-weight: 900;
    }

    .labelBanner {
        text-align: left;
    }

    .bannerConteiner {
        flex-direction: row;
    }

    .MainConteiner {
        max-width: 80%;
    }

    .seguroTitle {
        justify-content: start
    }

    .buttonRegister {
        justify-content: start;
    }

    .heartImage {
        transform: scale(0.8);
        left: 120px;
        top: 25px;
        position: absolute;
    }

    .phoneImage {
        transform: scale(0.8);
        left: 0px;
        top: 30px;
        position: absolute;
    }

    .bannerText {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .buttonRegister {
        max-width: 200px;
    }

    .buttonRegister button {
        font-size: 12px;
    }
}

/*Laptop*/
@media screen and (min-width:1024px) and (max-width:1199px) {
    .labelBanner p {
        font-weight: 900;
    }

    .labelBanner {
        text-align: left;
    }

    .bannerConteiner {
        flex-direction: row;
    }

    .MainConteiner {
        max-width: 80%;
    }

    .seguroTitle {
        justify-content: start
    }

    .buttonRegister {
        justify-content: start;
    }

    .heartImage {
        transform: scale(1);
        left: 190px;
        top: 50px;
        position: absolute;
    }

    .phoneImage {
        transform: scale(1);
        left: 10px;
        top: 80px;
        position: absolute;
    }
}

/*desktop*/
@media screen and (min-width:1200px) and (max-width:2096px) {
    .labelBanner p {
        font-weight: 900;
    }

    .labelBanner {
        text-align: left;
    }

    .bannerConteiner {
        flex-direction: row;
        flex-direction: row;

        flex-direction: row;

    }


    .MainConteiner {
        max-width: 80%;
    }

    .seguroTitle {
        justify-content: start
    }

    .buttonRegister {
        justify-content: start;
    }

    .heartImage {
        transform: scale(1);
        left: 200px;
        top: 60px;
        position: absolute;

    }

    .phoneImage {
        transform: scale(1);
        left: 15px;
        top: 100px;
        position: absolute;
    }

    .membershipCards,
    .membershipCardsSingle {
        display: flex;
        justify-content: center;
    }

    .cardConteiner {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    .membershipCard {
        min-height: 100px;
        flex: 0 1 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
    }

    .cardDisabled {
        background-color: #fff !important;
        filter: grayscale(100%) !important;
        opacity: 0.5 !important;
        pointer-events: none;
    }
}