.JoinConteiner {
    position: relative;
    background-color: transparent;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.TitleItem {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.JoinConteiner>div>h1 {
    padding-top: 40px;
}

.ImageConteiner {
    display: flex;
    width: 100%;
    margin: auto;
    flex-basis: 50%;
}

.StepPathsItem {
    flex-basis: 50%;
}

.Image {
    width: 95%;
    margin: auto;
    display: flex;
}

.PersonImg {
    padding: 10px;
    max-width: 221px;
    width: 100%;
    margin: 0 auto;
}

.element {
    display: flex;
    margin: 20px 0px 20px 0px;
}

.elementPoint {
    position: relative;
    display: flex;
}

.elementPoint>.circle {
    background-color: #4f9fcc;
    margin: 0px 10px 5px 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
}

.line {
    top: 10px;
    left: 18px;
    margin: 0px;
    padding-left: 10px;
    position: absolute;
    height: 5rem;
    border-left: 2.5px dotted #4f9fcc;
}

.lineFinzus {
    top: 10px;
    left: 18px;
    margin: 0px;
    padding-left: 10px;
    position: absolute;
    height: 5rem;
    border-left: 2.5px dotted rgb(255, 95, 84);
}

.elementText p {
    margin: 0px !important;
    font-size: 16px;
}



.titlePart {
    display: inline;
}

@media (min-width: 768px) {
    .titlePart {
        display: block;
    }
}

/*Mobile*/
@media screen and (min-width:0px) and (max-width:639px) {}

/*Tablet*/
@media screen and (min-width:640px) and (max-width:1023px) {

    .JoinConteiner {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

/*Laptop*/
@media screen and (min-width:1024px) and (max-width:1199px) {

    .JoinConteiner {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .line {
        height: 2rem;
    }

}

/*desktop*/
@media screen and (min-width:1200px) and (max-width:2096px) {

    .JoinConteiner {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .line {
        height: 4rem;
    }

    .TitleItem {
        position: relative;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }
}