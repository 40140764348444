:root {
    --bg-color: #039ECC;
    --bg-secondary-color: #C0E6F2;
    --bg-color-premium: #E6B331;
    --bg-secondry-color-premium: #FAD882;
    --border-radius: 10px;
    font-family: 'Montserrat' !important;
}

.InfoDetails {
    display: none;
    position: absolute;
    padding: 10px;
    font-family: monospace;
    background-color: #C0E6F2;
    border-radius: 10px;
}

.InfoDetails p {
    padding: 0px;
    margin: 0px;
}

.code {
    font-weight: 900;
}


.MembershipCard {
    border-radius: var(--border-radius);
    background-color: white;
    /* max-width: 525px; */
}

.header,
.body {
    padding: 0px;
}

.buttonsSuperior {
    margin-bottom: 15px;

}

.buttonsSuperior button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    color: #039ECC;
    padding: 10px;
    background-color: transparent;
    font-size: 19px;
    font-weight: bold;
    font-family: 'Montserrat';
    cursor: pointer;
}

/*Benefits*/
.benefits {
    font-size: 25px;
}

.labelBenefit {
    text-align: center;
    background-color: #C0E6F2;
    font-size: 20px;
}

.labelBenefit p {
    padding: 10px;
}

.BlueDetail {
    width: 100%;
    padding: 15px;
    border-radius: 0px 0px var(--border-radius) var(--border-radius);
}

.ItemFooter {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding: 5px 0px 5px 0px;
    text-align: left;
    gap: 1rem;
}

.ItemFooter p {
    font-size: 18px;
    /* padding-right: 12px; */
    margin: 0px;

}

.buttonConteiner {
    display: flex;
    justify-content: center;
    padding: 20px;
    max-width: 100%;
    margin: auto;
}

.buttonContinue {
    background-color: #039ECC;
    border: none;
    font-size: 16px;
    font-weight: 500;
    padding: 15px;
    margin: auto;
    color: white;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}

.MembershipConteiner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    /* width: 85%; */
    gap: 24px;
    margin: auto;
    align-items: flex-start;
}

.ElementTotal {
    /* width: 30%; */
}

@media screen and (min-width:0px) and (max-width:639px) {
    .MembershipConteiner {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .ElementTotal {
        width: 100%;
    }
}

@media screen and (min-width:640px) and (max-width:1023px) {
    .MembershipConteiner {
        /* align-items: center; */
        flex-direction: column;
    }

    .ElementTotal {
        width: 100%;
        max-width: 525px;
    }
}